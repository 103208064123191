import FooterContainer_DefaultWithFillLayersComponent from '@wix/thunderbolt-elements/src/components/FooterContainer/viewer/skinComps/DefaultWithFillLayers/DefaultWithFillLayers.skin';


const FooterContainer_DefaultWithFillLayers = {
  component: FooterContainer_DefaultWithFillLayersComponent
};


export const components = {
  ['FooterContainer_DefaultWithFillLayers']: FooterContainer_DefaultWithFillLayers
};

